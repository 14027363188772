import React from "react";
import { useState, useEffect } from "react";
import { fetchData } from "../api";
import ErrorModal from "../components/ErrorModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoCopyOutline } from "react-icons/io5";
import { ring2 } from "ldrs";
import Pagination from "../components/Pagination";
import { useNavigate } from "react-router-dom";

const Cardresultcomponent = () => {
  const [statsData, setStatsData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };
  const test = async () => {
    try {
      const res = await fetchData(
        `${process.env.REACT_APP_GNOSIS_TRANSACTIONS}`
      );
      // const response = await res.json();
      setStatsData(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError("Something went wrong. Please try later.");
      console.error("Error caught in loadData:", error.message);
    }
  };

  useEffect(() => {
    test();
  }, []);

  const [currentpage, setCurrentpage] = useState(1);
  const recordsperpage = 8;
  const indexoflastrecord = currentpage * recordsperpage;
  const indexoffirstrecord = indexoflastrecord - recordsperpage;
  const records = statsData.slice(indexoffirstrecord, indexoflastrecord);
  const nPages = Math.ceil(statsData.length / recordsperpage);
  const numbers = [...Array(nPages + 1).keys()].slice(1);

  function prePage(e) {
    e.preventDefault();
    if (currentpage !== 1) {
      setCurrentpage(currentpage - 1);
    }
  }
  function nextPage(e) {
    e.preventDefault();
    if (currentpage !== nPages) {
      setCurrentpage(currentpage + 1);
    }
  }
  function changeCPage(e, id) {
    e.preventDefault();
    setCurrentpage(id);
  }

  function copyText1(item) {
    /* Copy text into clipboard */
    navigator.clipboard.writeText(item);
  }

  const navigate = useNavigate();

  const handlehashClick = (txHash) => {
    navigate("/searchresult/searchhash", { state: { txHash } });
  };
  const handleFromClick = (param) => {
    navigate("/searchresult", { state: { param } });
  };

  ring2.register();
  return (
    <div className="mx-2 mt-2">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <span>
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="white"
            ></l-ring-2>
          </span>
        </div>
      ) : (
        <div>
          {!statsData ? (
            <div className="text-white flex items-center justify-center mt-16 text-[20px]">
              Data Not Found
            </div>
          ) : (
            <div className="overflow-x-auto my-12 bg-[#0F2434] rounded-3xl mx-[10px] sm:mx-auto lg:w-[60rem] xl:w-[85rem]">
              <table className="w-full table-auto bg-[#0F2434] rounded-3xl">
                <thead>
                  <tr className="bg-[#0F2434]">
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      Tx Hash
                    </th>
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      Block
                    </th>
                    <th className="px-6 py-3 text-left text-xs text-white uppercase tracking-wider font-semibold">
                      Type
                    </th>
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      From
                    </th>
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      To
                    </th>
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      Timestamp
                    </th>
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      TX Count
                    </th>
                    <th className="px-6 py-3 text-left text-xs text-white uppercase tracking-wider font-semibold">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#0F2434]  ">
                  {records?.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-[#071120] border-b-[1px] border-[#0F2434] w-[30rem]"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-[#0E83DB]">
                        <div className="flex">
                          <div data-tooltip-id={item.to.hash}>
                            <button onClick={() => handlehashClick(item?.hash)}>
                              {item?.hash.slice(0, 17)}...
                            </button>
                          </div>
                          <span className="pl-2">
                            <IoCopyOutline
                              className="hover:cursor-pointer"
                              onClick={() => copyText1(item?.hash)}
                            />
                          </span>
                          <ReactTooltip
                            id={item?.hash}
                            place="top"
                            className="z-40"
                            // variant="danger"
                            style={{
                              backgroundColor: "#040F1C",
                              color: "#0E83DB",
                            }}
                            content={item?.hash}
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.block_number || 0}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.tx_types?.slice(0, 13) || 0}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-[#0E83DB]">
                        <div className="flex items-center">
                          <div data-tooltip-id={item.from.hash}>
                            <button
                              onClick={() => handleFromClick(item?.from?.hash)}
                            >
                              {item?.from?.hash.slice(0, 17)}...
                            </button>
                          </div>
                          <span className="pl-2">
                            <IoCopyOutline
                              className="hover:cursor-pointer"
                              onClick={() => copyText1(item?.from?.hash)}
                            />
                          </span>
                          <ReactTooltip
                            id={item.from.hash}
                            place="top"
                            className="z-40"
                            // variant="danger"
                            style={{
                              backgroundColor: "#040F1C",
                              color: "#0E83DB",
                            }}
                            content={item?.from?.hash}
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-[#0E83DB]">
                        <div className="flex">
                          <div data-tooltip-id={item.to.hash}>
                            <button
                              onClick={() => handleFromClick(item?.to?.hash)}
                            >
                              {" "}
                              {item?.to?.hash.slice(0, 17)}...
                            </button>
                          </div>
                          <span className="pl-2">
                            <IoCopyOutline
                              className="hover:cursor-pointer"
                              onClick={() => copyText1(item?.to?.hash)}
                            />
                          </span>
                          <ReactTooltip
                            id={item.to.hash}
                            place="top"
                            className="z-40"
                            // variant="danger"
                            style={{
                              backgroundColor: "#040F1C",
                              color: "#0E83DB",
                            }}
                            content={item?.to?.hash}
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.timestamp?.slice(11, 13)}m ago
                        <span className="block font-semibold text-[12px] text-[#9CA0A7]">
                          {item.timestamp.slice(0, 10)}{" "}
                          {item.timestamp?.slice(11, 15)}
                        </span>{" "}
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.confirmations || 0}
                      </td> */}
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.value / 10000000000000000000 || 0}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-green-500">
                        {item?.result}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="sticky bottom-0 left-0 right-0">
                <ul className="text-white mx-auto w-full flex flex-row justify-center py-3 bg-[#071120] ">
                  <Pagination
                    nextPage={nextPage}
                    changeCPage={changeCPage}
                    prePage={prePage}
                    numbers={numbers}
                    currentpage={currentpage}
                  />
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
      <ErrorModal
        show={showModal}
        title="Error"
        message={errorMessage}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default Cardresultcomponent;
