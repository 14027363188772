import React, { useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ring2 } from "ldrs";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";

const TransactionData = ({ statsData, copyText, copyText1, isLoading }) => {
  const [currentpage, setCurrentpage] = useState(1);
  const recordsperpage = 8;
  const indexoflastrecord = currentpage * recordsperpage;
  const indexoffirstrecord = indexoflastrecord - recordsperpage;
  const records = statsData.slice(indexoffirstrecord, indexoflastrecord);
  const nPages = Math.ceil(statsData.length / recordsperpage);
  const numbers = [...Array(nPages + 1).keys()].slice(1);

  function prePage(e) {
    e.preventDefault();
    if (currentpage !== 1) {
      setCurrentpage(currentpage - 1);
    }
  }
  function nextPage(e) {
    e.preventDefault();
    if (currentpage !== nPages) {
      setCurrentpage(currentpage + 1);
    }
  }
  function changeCPage(e, id) {
    e.preventDefault();
    setCurrentpage(id);
  }

  const navigate = useNavigate();

  const handlehashClick = (txHash) => {
    navigate("/searchresult/searchhash", { state: { txHash } });
  };
  const handleFromClick = (from, to) => {
    navigate("/searchresult", { state: { from, to } });
  };
  ring2.register();

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center my-4">
          <span>
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="white"
            ></l-ring-2>
          </span>
        </div>
      ) : (
        <div>
          {statsData.length === 0 ? (
            <div className="text-white flex items-center justify-center my-4 text-[20px]">
              Data Not Found
            </div>
          ) : (
            <div className="rounded-3xl mb-20 mx-3 mt-4">
              <div className=" overflow-x-auto  rounded-3xl">
                <table className="items-center mx-auto w-[80rem] rounded-3xl text-left">
                  <thead className="bg-[#0F2434] rounded-lg text-white font-bold font-poppins ">
                    <tr className="text-white rounded-xl">
                      <th className="text-left pl-7 py-4 rounded-tl-3xl">
                        {" "}
                        TxHash{" "}
                      </th>
                      <th className="text-left pl-7 py-4">Block</th>
                      <th className="text-left pl-7 py-4">Method</th>
                      <th className="text-left pl-7 py-4">From</th>
                      <th className="text-left pl-7 py-4">To</th>
                      <th className="text-left pl-7 py-4">Timestamp</th>
                      <th className="text-left pl-7 py-4">Value</th>
                      <th className="text-left pl-7 py-4 rounded-tr-3xl">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {records.map((arr) => (
                      <tr
                        key={arr?.hash}
                        className="text-white bg-[#071120] border-b-[1px] border-[#0F2434] font-poppins font-bold text-[14px] "
                      >
                        <td className="pl-7 text-left py-3 text-[#0E83DB]">
                          <span data-tooltip-id={arr?.hash}>
                            <button onClick={() => handlehashClick(arr?.hash)}>
                              {arr?.hash?.slice(0, 14)}...
                            </button>
                          </span>
                          <ReactTooltip
                            id={arr?.hash}
                            place="top"
                            className=""
                            style={{
                              backgroundColor: "#040F1C",
                              color: "white",
                            }}
                            content={arr?.hash}
                          />
                        </td>
                        <td className="pl-7 text-left py-3 text-white ">
                          {arr.block}
                        </td>
                        <td className="pl-7 text-left py-3 ">Coin_Transfer</td>
                        {/* {String(arr.method).slice(0,8)} */}
                        <td className="pl-7 text-left py-3 flex items-center mt-2 text-[#0E83DB]">
                          <span data-tooltip-id={arr?.from?.hash}>
                            <button
                              onClick={() => handleFromClick(arr?.from?.hash)}
                            >
                              {arr?.from?.hash.slice(0, 14)}...{" "}
                            </button>
                          </span>
                          <ReactTooltip
                            id={arr?.from?.hash}
                            place="top"
                            className=""
                            // variant="danger"
                            style={{
                              backgroundColor: "#040F1C",
                              color: "#0E83DB",
                            }}
                            content={arr?.from?.hash}
                          />
                          <span className="inline-block pl-2">
                            <IoCopyOutline
                              className="hover:cursor-pointer"
                              onClick={() => copyText1(arr)}
                            />
                          </span>{" "}
                        </td>
                        <td className="pl-7 text-left py-3  text-[#0E83DB]">
                          <span data-tooltip-id={arr?.to?.hash}>
                            <button
                              onClick={() => handleFromClick(arr?.to?.hash)}
                            >
                              {arr.to?.hash.slice(0, 14)}...
                            </button>
                          </span>
                          <span className="inline-block pl-2 ">
                            <IoCopyOutline
                              className="hover:cursor-pointer"
                              onClick={() => copyText(arr)}
                            />
                          </span>{" "}
                          <ReactTooltip
                            id={arr?.to?.hash}
                            place="top"
                            className=""
                            // variant="danger"
                            style={{
                              backgroundColor: "#040F1C",
                              color: "#0E83DB",
                            }}
                            content={arr?.to?.hash}
                          />
                        </td>
                        <td className="pl-7 text-left py-3">
                          {arr.timestamp.slice(11, 13)}m ago
                          <span className="block font-semibold text-[14px] text-[#9CA0A7]">
                            {arr.timestamp.slice(0, 10)}{" "}
                            {arr.timestamp.slice(11, 15)}
                          </span>
                        </td>
                        <td className="pl-7 text-left py-3">
                          {arr.value.slice(0, 4)}
                        </td>
                        <td className="pl-7 text-left py-3">
                          <button className="text-green-500">
                            {arr.result.slice(0, 8)}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="sticky bottom-0 left-0 right-0 mx-auto w-[80rem]">
                  <ul className="text-white mx-auto w-full flex flex-row justify-center py-3 bg-[#071120] ">
                    <Pagination
                      nextPage={nextPage}
                      changeCPage={changeCPage}
                      prePage={prePage}
                      numbers={numbers}
                      currentpage={currentpage}
                    />
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
// };

export default TransactionData;
