// src/services/blockchainService.js
import { useState, useEffect } from "react";
import Web3 from "web3";
import ABI from "./abi.json";
import { contractAddress } from "./contractAddress";

const CONTRACT_ABI = ABI;
const CONTRACT_ADDRESS = contractAddress;
const IDEA_TESTNET_CHAIN_ID = "0xb90"; // Chain ID for IDEA Testnet (2960 in hex)

let web3;
let contract;
export const initWeb3 = (provider) => {
  if (!web3) {
    web3 = new Web3(provider);
    contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
    // console.log(contract, "web3", CONTRACT_ADDRESS);
  }
};

export const checkNetwork = async (provider) => {
  try {
    const networkId = await provider.request({ method: "eth_chainId" });
    if (networkId !== IDEA_TESTNET_CHAIN_ID) {
      throw new Error("Please switch to the IDEA Testnet");
    }
  } catch (error) {
    console.error("Error checking network:", error);
    throw error;
  }
};

// this is the old code

export const switchToIdeaTestnet = async (wallet) => {
  if (!wallet || !wallet.provider) {
    console.error("Wallet or provider is not available:", wallet);
    throw new Error("Wallet provider is not available.");
  }

  try {
    const chainId = await wallet.provider.request({ method: "eth_chainId" });

    if (chainId !== IDEA_TESTNET_CHAIN_ID) {
      try {
        await wallet.provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: IDEA_TESTNET_CHAIN_ID }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await wallet.provider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: IDEA_TESTNET_CHAIN_ID,
                  chainName: "IDEA Testnet",
                  rpcUrls: ["https://node1.ideascan.io/"],
                  nativeCurrency: {
                    name: "IDEA",
                    symbol: "IDEA",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://api.ideascan.io/"],
                },
              ],
            });
          } catch (addError) {
            throw new Error("Failed to add IDEA Testnet to MetaMask.");
          }
        } else {
          throw new Error("Failed to switch to IDEA Testnet.");
        }
      }
    }
  } catch (error) {
    console.error("Error in switchToIdeaTestnet:", error);
    throw error;
  }
};

export const getAccounts = async (provider) => {
  initWeb3(provider);
  try {
    await switchToIdeaTestnet({ provider }); // Ensure the network is switched before checking
    await checkNetwork(provider); // This should now pass after switching to the correct network
    const accounts = await provider.request({ method: "eth_requestAccounts" });
    return accounts;
  } catch (error) {
    console.error("Error fetching accounts:", error);
    throw error;
  }
};

export const _fetchAllDeployedTokens = async () => {
  if (!contract) {
    throw new Error("Contract is not initialized");
  }
  try {
    const result = await contract.methods.getAllDeployedTokens().call();
    return result;
  } catch (error) {
    console.error("Error calling getAllDeployedTokens function:", error);
    throw error;
  }
};

// export const _createToken = async (args) => {
//   if (!contract) {
//     throw new Error("Contract is not initialized");
//   }
//   console.log("Contract instance:", contract);
//   console.log("From address:", args.from);
//   try {
//     // Convert initialSupply from Ether to Wei
//     const initialSupplyInWei = web3.utils.toWei(args.initialSupply, "ether");
//     console.log(initialSupplyInWei, "initialSupply");
//     const transaction = await contract.methods
//       .createToken(
//         args.name, // string
//         args.symbol, // string
//         args.initialSupply, // uint256 in Wei
//         args.tokenOwner // address
//       )
//       .send({ from: args.from });

//     console.log("Transaction successful", transaction);

//     const tokenCreatedEvent = transaction.events.TokenCreated;

//     if (!tokenCreatedEvent) {
//       throw new Error("TokenCreated event not found in transaction receipt.");
//     }

//     // Extract tokenAddress from the event arguments
//     const { tokenAddress } = tokenCreatedEvent.returnValues;
//     return tokenAddress;
//   } catch (error) {
//     console.error("Error sending transaction:", error);
//     throw error;
//   }
// };

export const useBlockchainDeployToken = (wallet) => {
  const [account, setAccount] = useState("");
  const [networkError, setNetworkError] = useState("");

  useEffect(() => {
    if (wallet && wallet.provider) {
      const init = async () => {
        try {
          await switchToIdeaTestnet(wallet); // Switch to the correct network first
          const accounts = await getAccounts(wallet.provider); // Then get the accounts
          setAccount(accounts[0]);
        } catch (error) {
          setNetworkError(error.message);
          console.error("Error initializing:", error);
        }
      };
      init();
    }
  }, [wallet]);

  const getAllDeployedTokens = async () => {
    try {
      const result = await _fetchAllDeployedTokens();
      return result;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const createToken = async (args) => {
  //   console.log(args, "argsss");
  //   if (!contract) {
  //     throw new Error("Contract is not initialized");
  //   }
  //   try {
  //     const tokenAddress = await _createToken(args);
  //     return tokenAddress;
  //   } catch (error) {
  //     console.error("Error creating token:", error);
  //     throw error;
  //   }
  // };

  // const createToken = async (args) => {
  //   if (!contract) {
  //     throw new Error("Contract is not initialized");
  //   }
  //   if (!account) {
  //     throw new Error("Account is not initialized");
  //   }
  //   console.log("we are here", args);
  //   try {
  //     // Convert initialSupply from Ether to Wei
  //     const initialSupplyInWei = web3.utils.toWei(args.initialSupply, "ether");
  //     console.log(initialSupplyInWei, "initialsuply");
  //     const transaction = await contract.methods
  //       .createToken(
  //         args.name, // string
  //         args.symbol, // string
  //         initialSupplyInWei, // uint256 in Wei
  //         args.tokenOwner // address
  //       )
  //       .send({ from: account });

  //     console.log("Transaction successful", transaction);

  //     const tokenCreatedEvent = transaction.events.TokenCreated;

  //     if (!tokenCreatedEvent) {
  //       throw new Error("TokenCreated event not found in transaction receipt.");
  //     }

  //     // Extract tokenAddress and tokenABI from the event arguments
  //     const { tokenAddress, tokenABI } = tokenCreatedEvent.returnValues;
  //     return tokenAddress;
  //     // console.log(tokenAddress, tokenABI, "tokenAddress, tokenABI");
  //   } catch (error) {
  //     console.error("Error sending transaction:", error);
  //     throw error;
  //   }
  // };

  const createToken = async (args) => {
    if (!contract) {
      throw new Error("Contract is not initialized");
    }

    try {
      // Convert initialSupply from Ether to Wei (if it's not already in Wei)
      const initialSupplyInWei = web3.utils.toWei(args.initialSupply, "ether");
      console.log(initialSupplyInWei, "initialsuply");

      const transactionParams = {
        from: args.tokenOwner, // Use the provided tokenOwner as the sender
      };

      // Detect if the network supports EIP-1559
      const latestBlock = await web3.eth.getBlock("latest");
      const supportsEIP1559 = latestBlock.baseFeePerGas !== undefined;

      console.log(supportsEIP1559, "these are supportsEIP1559");

      if (supportsEIP1559) {
        // Use EIP-1559 parameters
        transactionParams.maxFeePerGas = web3.utils.toWei("2", "gwei");
        transactionParams.maxPriorityFeePerGas = web3.utils.toWei("1", "gwei");
      } else {
        // Use legacy gasPrice
        transactionParams.gasPrice = await web3.eth.getGasPrice();
      }

      const transaction = await contract.methods
        .createToken(
          args.name, // string
          args.symbol, // string
          initialSupplyInWei, // uint256 in Wei
          args.tokenOwner // address
        )
        .send(transactionParams);

      console.log("Transaction successful", transaction);
      return transaction?.events?.TokenCreated?.returnValues[0] || "";
    } catch (error) {
      console.error("Error sending transaction:", error);
      throw error;
    }
  };

  return {
    account,
    switchToIdeaTestnet,
    networkError,
    getAllDeployedTokens,
    createToken,
  };
};
