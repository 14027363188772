import React, { useState, useEffect } from "react";
import Charts from "./Charts";

const Hero = () => {
  const [userData, setUserData] = useState({
    labels: [],
    datasets: [
      {
        label: "User Gained",
        data: [],
        backgroundColor: ["#0C71BC", "#5DAFEB"],
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_GNOSIS_STATS_GRAPHS}`);
        const { chart_data } = await res.json();

        if (chart_data && chart_data.length > 0) {
          // Sort the data in ascending order (oldest to newest)
          const sortedData = chart_data.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );

          // Slice the last 12 entries (latest 12 dates in ascending order)
          const latestData = sortedData.slice(-12); // Extracts the last 12 records

          // Extract the day part of the date for labels and transaction counts for data
          const latestDates = latestData.map((item) => item.date?.substring(8)); // Extracting day part
          const txCounts = latestData.map((item) => item.tx_count / 1000); // Normalizing tx_count

          // Update chart data with the sorted latest dates and corresponding transaction counts
          setUserData((prevUserData) => ({
            ...prevUserData,
            labels: latestDates,
            datasets: [
              {
                ...prevUserData.datasets[0],
                data: txCounts,
              },
            ],
          }));
        } else {
          // Use dummy data if API does not return data
          setDummyData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDummyData();
      }
    };

    const setDummyData = () => {
      setUserData({
        labels: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ],
        datasets: [
          {
            label: "User Gained",
            data: [1.2, 2.5, 1.8, 3.0, 1.5, 2.3, 1.9, 3.2, 1.7, 2.0, 2.8, 3.1],
            backgroundColor: ["#0C71BC", "#5DAFEB"],
          },
        ],
      });
    };

    fetchData();
  }, []);

  return (
    <div>
      <Charts chartData={userData} />
    </div>
  );
};

export default Hero;
