import React, { useState } from "react";
import SearchBar from "../components/SearchBar.jsx";
import { useEffect } from "react";
import { fetchData } from "../api.js";
import TransactionChart from "../components/TransactionChart.jsx";
import TransactionData from "../components/TransactionData.jsx";
import ErrorModal from "../components/ErrorModal.jsx";

const Transactions = () => {
  const [statsData, setStatsData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({
    labels: [],
    datasets: [
      {
        label: "Transaction History",
        data: [],
        borderWidth: 2,
      },
    ],
  });

  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const [singleItem, setSingleItem] = useState();

  const test = async () => {
    try {
      const res = await fetchData(
        `${process.env.REACT_APP_GNOSIS_TRANSACTIONS}`
      );
      setStatsData(await res);
      setSingleItem(await res[0]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError("Something went wrong. Please try later.");
      console.error("Error caught in loadData:", error.message);
    }
  };
  useEffect(() => {
    test();
    const interval = setInterval(() => {
      test();
    }, 100000); // 10 seconds in milliseconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (statsData.length > 0) {
      const labels = statsData?.map((arr) => arr.hash.slice(0, 14) + "...");
      const data = statsData?.map((arr) => arr.value.slice(0, 4));
      setUserData({
        labels: labels,
        datasets: [
          {
            label: "Transaction History",
            data: data,
            borderWidth: 2,
          },
        ],
      });
    }
  }, [statsData]);

  function copyText(arr) {
    /* Copy text into clipboard */
    navigator.clipboard.writeText(arr?.to?.hash);
  }
  function copyText1(arr) {
    /* Copy text into clipboard */
    navigator.clipboard.writeText(arr?.from?.hash);
  }

  return (
    <div>
      <SearchBar />
      <div className="md:w-[40rem] lg:w-[60rem] xl:w-[80rem] mx-auto pl-8 sm:pl-0">
        <h1 className="font-bold font-poppins text-2xl mt-3 text-left text-white">
          Transactions
        </h1>
      </div>
      <TransactionChart singleItem={singleItem} userData={userData} />
      {/*  */}

      <TransactionData
        copyText={copyText}
        copyText1={copyText1}
        statsData={statsData}
        isLoading={isLoading}
      />
      <ErrorModal
        show={showModal}
        title="Error"
        message={errorMessage}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default Transactions;
