import React from "react";
import { useState } from "react";
import SearchBar from "../components/SearchBar.jsx";
import { useEffect } from "react";
import { GiBrokenBone } from "react-icons/gi";
import { fetchData } from "../api.js";
import { ring2 } from "ldrs";
import ErrorModal from "../components/ErrorModal.jsx";
import Pagination from "../components/Pagination.jsx";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoCopyOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Contracts = () => {
  const [statsData, setStatsData] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const recordsperpage = 10;
  const indexoflastrecord = currentpage * recordsperpage;
  const indexoffirstrecord = indexoflastrecord - recordsperpage;
  const records = statsData.slice(indexoffirstrecord, indexoflastrecord);
  const nPages = Math.ceil(statsData.length / recordsperpage);
  const numbers = [...Array(nPages + 1).keys()].slice(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  function copyText1(arr) {
    /* Copy text into clipboard */
    navigator.clipboard.writeText(arr?.address);
  }

  const test = async () => {
    try {
      const res = await fetchData(`${process.env.REACT_APP_GNOSIS_TOKENS}`);
      setStatsData(res.items);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError("Something went wrong. Please try later.");
      console.error("Error caught in loadData:", error.message);
    }
  };

  useEffect(() => {
    test();
  }, []);

  function prePage(e) {
    e.preventDefault();
    if (currentpage !== 1) {
      setCurrentpage(currentpage - 1);
    }
  }
  function nextPage(e) {
    e.preventDefault();
    if (currentpage !== nPages) {
      setCurrentpage(currentpage + 1);
    }
  }
  function changeCPage(e, id) {
    e.preventDefault();
    setCurrentpage(id);
  }
  ring2.register();

  const navigate = useNavigate();

  const handleContractClick = (address) => {
    navigate("/searchresult", { state: { address } });
  };

  return (
    <div>
      <SearchBar />
      <div className="lg:w-[60rem] xl:w-[80rem] w-[10rem] mx-auto pl-7 sm:pl-0 mb-4">
        <h1 className="font-bold font-poppins text-[20px] sm:text-[32px] mt-3 text-white text-left">
          Contracts
        </h1>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <span>
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="white"
            ></l-ring-2>
          </span>
        </div>
      ) : (
        <div>
          {statsData.length === 0 ? (
            <div className="text-white flex items-center justify-center my-4 text-[20px]">
              Data Not Found
            </div>
          ) : (
            <div className="overflow-x-auto mx-3 sm:mx-4 rounded-3xl">
              <div className="max-h-[500px] md:max-h-[70rem] mx-auto lg:w-[60rem] xl:w-[80rem] w-full overflow-y-auto  rounded-3xl mb-12">
                <table className="items-center mx-auto lg:mx-auto w-full lg:w-[60rem] xl:w-[80rem] rounded-3xl text-left ">
                  <thead className="bg-[#0F2434] rounded-lg text-white text-[14px] md:text-lg sticky top-0">
                    <tr className="text-white rounded-xl">
                      <th className="text-center pr-20 py-4 rounded-tl-3xl w-[20rem]">
                        {" "}
                        Name{" "}
                      </th>
                      <th className="text-left pl-7 pr-4 py-4">Type</th>
                      <th className="text-left pl-7 pr-4 py-4">
                        Contract Adress
                      </th>
                      <th className="text-left pl-7 pr-4 py-4 rounded-tr-3xl">
                        Exchange Rate
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {records.map((arr) => (
                      <tr
                        key={arr.address}
                        className="text-white bg-[#071120] border-b-[1px] border-[#0F2434] text-[10px] sm:text-[14px]"
                      >
                        <td className="pl-9 text-left py-3 flex items-center w-[20rem]">
                          {(arr.icon_url && (
                            <img className="w-12" src={arr.icon_url} />
                          )) || <GiBrokenBone size={34} color="white" />}
                          <span className="pl-6">{arr.name}</span>
                        </td>
                        <td className="pl-7 text-left py-3">IDE-20</td>
                        <td className="pl-7 text-left py-4 sm:py-3 flex items-center text-[#0E83DB]">
                          {/* {arr.address.slice(0, 13)}... */}
                          <div data-tooltip-id={arr.address}>
                            <button
                              onClick={() => handleContractClick(arr.address)}
                            >
                              {arr.address.slice(0, 17)}...
                            </button>
                          </div>
                          <span className="pl-2">
                            <IoCopyOutline
                              className="hover:cursor-pointer"
                              onClick={() => copyText1(arr)}
                            />
                          </span>
                          <ReactTooltip
                            id={arr.address}
                            place="top"
                            className="z-40"
                            // variant="danger"
                            style={{
                              backgroundColor: "#040F1C",
                              color: "#0E83DB",
                            }}
                            content={arr.address}
                          />
                        </td>
                        <td className="pl-7 text-left py-3 text-[#0E83DB]">
                          {(arr.exchange_rate && arr.exchange_rate) || 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="sticky bottom-0 left-0 right-0">
                  <ul className="text-white mx-auto w-full items-center py-7 flex flex-row justify-center bg-[#071120]">
                    <Pagination
                      nextPage={nextPage}
                      changeCPage={changeCPage}
                      prePage={prePage}
                      numbers={numbers}
                      currentpage={currentpage}
                    />
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <ErrorModal
        show={showModal}
        title="Error"
        message={errorMessage}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default Contracts;
