import React from "react";
import meta from "../assests/svgs/metamask-icon.svg";
import { useMetaMask } from "../ContextApi/MetaMaskProvider";
import { toast, ToastContainer } from "react-toastify";
import { IoCopyOutline } from "react-icons/io5";
import { sendNativeTokens } from "../../src/nativeFaucet";
import { useState } from "react";
import { ethers } from "ethers";
import ErrorModal from "../components/ErrorModal";

const Faucet = () => {
  const { address, connectWallet, copyText } = useMetaMask();
  const [userAddress, setUserAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [text, setText] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const faucetPrivateKey =
    "d2a0ccb4826c312e350cb23097909c0971e2455fc9b1076e919d64b814c84c2a"; // Private key of the faucet wallet
  const providerUrl = "https://node1.ideascan.io/"; // e.g., Infura, Alchemy, or public RPC URL

  const handleFaucetRequest = async () => {
    if (!userAddress) {
      toast.error("Please enter a valid address.");
      return;
    }
    if (typeof window.ethereum === "undefined") {
      toast.error("MetaMask is not installed.");
      return;
    }

    // Create a provider from MetaMask
    const provider = new ethers.BrowserProvider(window.ethereum);
    const accounts = await provider.listAccounts();

    // If no accounts are connected, ask the user to connect
    if (!accounts.length) {
      await connectWallet();
      return; // Call your custom connectWallet function to handle wallet connection
    }

    // Use the connected wallet address as the key
    const connectedAddress = accounts[0].address || String(accounts[0]);
    // console.log(connectedAddress, "this is connected address");

    // Check for faucet usage using the connected address
    const lastRequestTime = localStorage.getItem(`faucet_${connectedAddress}`);
    const currentTime = new Date().getTime();

    // 24 hours in milliseconds
    const restrictionTime = 24 * 60 * 60 * 1000;

    if (lastRequestTime) {
      // Convert lastRequestTime to a number if stored as a string
      const parsedLastRequestTime = Number(lastRequestTime);

      if (currentTime - parsedLastRequestTime < restrictionTime) {
        // Calculate remaining time in hours
        const remainingTimeInHours = (
          (parsedLastRequestTime + restrictionTime - currentTime) /
          (60 * 60 * 1000)
        ).toFixed(2);

        // Convert remaining time to minutes and seconds
        const totalMinutes = Math.floor(remainingTimeInHours * 60);
        const seconds = Math.round(
          (remainingTimeInHours * 60 - totalMinutes) * 60
        );

        setShowModal(true);
        setText("Attention!");
        setErrorMessage("You can not request before 24 hours");
        return;
      }
    }

    setIsLoading(true);
    try {
      // Send native tokens (ETH, BNB, etc.) from the faucet wallet to the user address
      const txResponse = await sendNativeTokens(
        userAddress,
        "0.1",
        faucetPrivateKey,
        providerUrl
      ); // 0.1 ETH example
      localStorage.setItem(`faucet_${connectedAddress}`, currentTime);
      setShowModal(true);
      setText("Transaction successful!");
      setUserAddress("");
      setErrorMessage(`${txResponse.hash.slice(0, 24)}.....`);
      // toast.success(`Transaction successful! Tx Hash: ${txResponse.hash}`);
    } catch (error) {
      toast.error("Failed to send tokens.");
      setUserAddress("");
    } finally {
      setIsLoading(false);
    }
  };

  const notify = () => {
    toast("Address Copied", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progressStyle: { background: "#0061f2" },
      theme: "info",
    });
  };

  const handleCopyText = () => {
    copyText();
    notify();
  };

  const handleConnect = async () => {
    try {
      await connectWallet();
    } catch (error) {
      console.error("Connection error:", error);
      setText("Connection error");
      setErrorMessage("Please check your internet connection!");
      // Handle error as needed
    }
  };

  return (
    <div className="flex flex-col lg:flex-row lg:justify-between max-w-[20rem] sm:max-w-xl lg:max-w-[62rem] h-[38rem] md:h-[38rem] lg:h-[22rem] bg-[#252527] my-12 lg:mt-20 mx-auto rounded-lg items-center justify-center">
      <div className="flex flex-col text-white justify-center mx-auto items-center sm:items-start">
        <label htmlFor="#" className="my-2">
          Enter Address
        </label>
        <input
          type="text"
          placeholder="Enter your wallet adress"
          value={userAddress}
          onChange={(e) => setUserAddress(e.target.value)}
          className="bg-[#3B3B3D] py-2 rounded-lg px-3 w-[18rem] sm:w-[25rem] my-2 outline-none"
        />
        <p className="mb-8 w-60 sm:w-80">
          The drops are limited to 0.1 request every 24 hours
        </p>
        <div>
          <button
            onClick={() => handleFaucetRequest()}
            disabled={isLoading}
            className="bg-blue-500 rounded-md p-2"
          >
            {isLoading ? "Processing..." : " Request Funds"}
          </button>
        </div>
      </div>
      <hr className="bg-[#3B3B3D] h-1 w-72 md:w-[28rem] lg:h-56 lg:w-0.5 my-4 lg:my-0" />
      <div className="bg-[#3B3B3D] w-[17rem] sm:w-[25rem] h-[10rem] sm:h-48 flex flex-col justify-center items-center lg:mx-12 xl:mx-12 rounded-lg ">
        <div>
          <p className="px-4 text-white">
            Use the button below to add Idea network to your metamask wallet
            extension
          </p>
        </div>
        <div className="pt-4">
          {/* <button className="border-transparent rounded px-3 py-2 text-white bg-[#252527] flex">
            <img src={meta} style={{ width: "20px" }} alt="meta" />
            <span className="ml-2">Add Idea Network</span>
          </button> */}
          {address ? (
            <div className="flex ">
              <div
                onClick={() => handleCopyText()}
                className="text-[12px] text-white p-2 mr-4 cursor-pointer flex items-center h-[40px] w-[214px] font-medium text-base rounded-lg hover:bg-[#0668AF] ease-in-out duration-200 bg-[#0F2434]"
              >
                {address.substring(0, 9)}...
                <span className="">{address.substring(34, 43)}</span>
                <span className="pl-2 text-white">
                  <IoCopyOutline className="hover:cursor-pointer" />
                </span>
              </div>
            </div>
          ) : (
            <div className="hidden sm:block">
              <button
                className="border-transparent rounded px-3 py-2 hover:bg-[#0668AF] bg-[#0F2434] flex items-center "
                onClick={handleConnect}
              >
                <img
                  src={meta}
                  style={{ width: "20px" }}
                  className="hidden sm:block"
                  alt="meta"
                />
                <span className="ml-2 text-white">Add Idea Network</span>
              </button>
            </div>
          )}
          <ToastContainer toastStyle={{ backgroundColor: "black" }} />
        </div>
      </div>
      <ErrorModal
        show={showModal}
        title={text}
        message={errorMessage}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default Faucet;
